import { Lexend } from "next/font/google";
import { rgba } from 'polished';
import theme from 'src/components/themes/DefaultTheme';
import { createGlobalStyle } from 'styled-components';

const lexend = Lexend({
	weight: ["200", "400", "700"],
	display: 'swap',
	subsets: ["latin"],
	fallback: ["sans-serif"]
});

//#region [Props]
type GlobalStyleProps = {
	//appData: ICommonAppData
};
//#endregion

//#region [Styles]
const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
	// MAYBE: This should be in layout!
	:root {

			--maincolor: ${theme.mainColor};
			--iconcolor: ${theme.textColor};
			--backgroundcolor: ${theme.bgColor};
			--backgroundalpha: ${rgba(theme.bgColor, 0.4)};
			--backgroundalphaMobile: ${rgba(theme.bgColor, 0.8)};
			--backgroundalphaheavy: ${rgba(theme.bgColor, theme.generalAlpha)};
			--textcolor: ${theme.textColor};
			--textcolordark: ${theme.textColorDark};;
			--inlinelinkcolor: ${theme.palette.turkis2};
			--inlinevisitedlinkcolor: ${theme.palette.lysGrå};
			--kinoklubbcolor: ${theme.kinoklubbColor};
			--buttoncolor: ${theme.buttonColor};
			--buttonactivecolor: ${theme.palette.knappAktivFarge};
			--buttoncolor1: ${theme.palette.knappFarge1};
			--buttoncolorAlpha: ${rgba(theme.buttonColor, theme.generalAlpha)};
			--buttontextcolor: ${theme.textColor};
			--page-padding: ${theme.maxContentPadding}; // default value to prevent it being 0

			--panel-borderradius: 15px;
            --max-content-width: ${theme.maxContentWidth}px;


	}
	// MAYBE: END

	* {
		box-sizing: border-box;
	}
	*:before {
		box-sizing: inherit;
	}
	*:after {
		box-sizing: inherit;
	}

	html {
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;

		font: 112.5%/1.45em sans-serif;
		box-sizing: border-box;
		max-width: 100vw;
	}

	body {
		min-height: 100vh;
		min-height: 100dvh;
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		max-width: 100vw;
		overflow-x: hidden;

		background-color: var(--backgroundcolor, ${theme.bgColor});
        background-repeat: no-repeat;
        background-attachment: fixed;

		&.noscroll {
			overflow: hidden;
			height: 100vh;

			main>nav {
				z-index: -1;
			}
		}

		${lexend.style};
	}

	main {
		height: 100%;
		min-height: 100vh;
		min-height: 100dvh;
	}

	button {
		${lexend.style};
	}

	h1 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
		line-height: ${theme.h1LineHeight};
	}
	h2 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
	}
	h3 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
	}
	h4 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
	}
	h5 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
	}
	h6 {
		font-size: ${theme.fonts.tittelS.size};
		margin: 0;
		padding: 0;
	}

	p {
		display: block;
		margin: 1em 0;
	}

	img {
		max-width: 100%;
		height: auto;
		display: block;
		width: 100%;
	}
`;
//#endregion

export default GlobalStyle;